<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="change(index, 'status')"
          class="li"
          :class="{ active: from.status == index }"
          v-for="(item, index) in 3"
          :key="index"
        >
          {{ index == 0 ? "审核中" : index == 1 ? "审核成功" : "审核失败" }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="change(1, 'type')" class="li nav-link" :class="{ active: from.type == 1 }">我的审核</div>
        <div v-if="userInfo.position != 4 && userInfo.position != 5" @click="change(2, 'type')" class="li nav-link" :class="{ active: from.type == 2 }">审核他人</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--audit-list start-->
      <div class="audit-list">
        <!--audit-item start-->
        <template v-for="(item,index) in list" >
          <router-link :to="`/personage/reportDetail?id=${item.id}&status=${from.status}&type=${from.type}`" >
            <div class="audit-item" :key="index">
              <div class="item-l">
                <div class="item-img">
                  <img src="../../assets/image/personage/a1.jpg" />
                </div>
                <div class="item-txt">
                  <div class="t">成交报告</div>
                  <div class="c color-6">{{ item.type == 1 ? '线上' : '线下' }}签约</div>
                </div>
              </div>
              <div class="item-r" v-if="from.status == 0">
                <div class="flex-end">
                  <template v-if="from.type == 1">
                    <el-button class="audit-list-btn red m-b-10"
                      >取消</el-button
                    >
                  </template>
                  <template v-else>
                    <el-button class="audit-list-btn red m-b-10 m-r-10"
                      >拒绝</el-button
                    >
                    <el-button class="audit-list-btn blue m-b-10"
                      >同意</el-button
                    >
                  </template>
                </div>
                <div class="item-date">{{ item.createtime }}</div>
              </div>
            </div>
          </router-link>
        </template>

        <!--audit-item end-->
      </div>
      <!--audit-list end-->
    </div>
    <!--ind-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      from: {
        status: 0,
        type: 1,
        page: 1,
      },
    };
  },
  methods: {
    //切换
    change(index, type) {
      if (this.from[type] == index) return
      this.from[type] = index
      this.init();
    },
    init() {
      this.from.page = 1;
      this.list = [];
      this.getList();
    },
    //获取成交报告列表
    getList() {
      this.$axios.baoGaoDeal(this.from).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    cursor: pointer;
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}
.ind-main {
  background: #fff;
  padding: 10px 40px;
}
.audit-list {
  .audit-list-btn {
    height: 40px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 40px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    border-bottom: 1px solid #ebebeb;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    .item-l {
      display: flex;
      width: calc(100% - 150px);
      .item-img img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
      }
      .item-txt {
        margin-left: 16px;
        width: calc(100% - 80px);
        .t {
          font-size: 18px;
          margin: 5px 0px;
          line-height: 1.6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .c {
          font-size: 14px;
          margin: 5px 0px;
          line-height: 1.6;
        }
      }
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:before {
    display: block;
    position: absolute;
    top: 50%;
    left: -20px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #ff2121;
    content: "";
  }
  .audit-item:nth-last-child(1) {
    border: none;
  }
}
</style>
